import store from "../store";
import { mockActionTypes } from "./saga";
/**
 * @module MockSaga
 */

/**
 * mock auth
 * @param {string} type
 */
export function mockAuth(type, phoneNumber) {
  store.dispatch({
    type: mockActionTypes.MOCK_AUTH,
    payload: {
      type: type,
      phoneNumber: phoneNumber
    }
  });
}

/**
 * mock verify OTP
 * @param {string} loginOtp
 * @param {string} userName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function mockAuthVerifyOtp(type, phoneNumber, loginOtp, userName, ) {
  store.dispatch({
    type: mockActionTypes.MOCK_AUTH_VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      userName: userName,
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * mock login
 * @param {object} data
 */
export function mockLogin(data) {
  store.dispatch({
    type: mockActionTypes.MOCK_ABDM_LOGIN,
    payload: data
  });
}
