/**
 * @module AuthSaga
 */

import store from "../store";
import { authActionTypes } from "./saga";

/**
 * Sends OTP to the Phone number in payload
 * Type indicates the authentication type login or sign in
 * @param {string} phoneNumber
 * @param {string} type
 */
export function sendOtp(phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.SEND_OTP,
    payload: {
      phoneNumber: phoneNumber,
      type: type,
    },
  });
}

/**
 * Verifies OTP from the Phone number in payload
 * clinicName indicates the name from payload
 * Type indicates the authentication type login or sign in
 * @param {string} loginOtp
 * @param {string} clinicName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function verifyOtp(loginOtp, clinicName, phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      clinicName: clinicName,
      phoneNumber: phoneNumber,
      type: type,
    },
  });
}

/**
 * Login in using OTP from payload
 * @param {string} loginOtp
 */
export function login(loginOtp) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: {
      loginOtp: loginOtp,
    },
  });
}

/**
 * Clears the credentials after login
 */
export function clearCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_CREDENTIAL,
  });
}

/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT,
  });
}

/**
 * Update Auth Info
 * @param {string} accessToken
 * @param {string} uid
 * @param {string} phoneNumber
 */
export function addAuthInfo(data) {
  store.dispatch({
    type: authActionTypes.ADD_AUTH_INFO,
    payload: {
      accessToken: data.accessToken,
      uid: data.uid,
      phoneNumber: data.phoneNumber,
    },
  });
}

/**
 * Update Access token
 * @param {string} accessToken
 */
export function forceRefreshAccessToken(shouldUpdateAccessStatus) {
  store.dispatch({
    type: authActionTypes.REFRESH_ACCESS_TOKEN,
    payload: {
      shouldUpdateAccessStatus: shouldUpdateAccessStatus,
    },
  });
}
