import React from "react";
import "./Header.css";
import { BackArrowIcon, ExitIcon } from "../../Assets/assets";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";
export default function Header(props) {
  return (
    <div className="height-64px background-color-primary inherit-parent-width display-flex flex-align-items-center padding-large">
      <div className="flex-justify-content-space-between inherit-parent-width">
        <div className="display-flex flex-align-items-center flex-justify-content-center">
          {props.backOnClick && (
            <div
              data-cy="back-arrow-button"
              className="display-flex flex-align-items-center flex-justify-content-center padding-right-default"
              onClick={props.backOnClick}
            >
              <BackArrowIcon fill="#FFFFFF" />
            </div>
          )}
          <div className="font-size-default font-color-white font-family-RHD-medium">
            {props.title}
          </div>
        </div>

        {props.logoutOnClick && (
          <div data-cy="logout-button" onClick={props.logoutOnClick}>
            <ExitIcon fill="#FFFFFF" />
          </div>
        )}

        {!props.logoutOnClick && (
          <img
            src={chennaiCorporationLogo}
            alt="chennai-corporation-logo"
            width="30px"
          />
        )}
      </div>
    </div>
  );
}
