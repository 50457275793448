const complaintsReducer = (
  state = {
    loading: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_COMPLAINTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_COMPLAINTS":
      return {
        ...state,
        data: action.payload,
      };

    // case "UPDATE_COMPLAINTS":
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       [action.payload.complaintId]: action.payload.data,
    //     },
    //   };

    case "RESET":
      return {
        loading: false,
        data: null,
      };

    default:
      return state;
  }
};
export default complaintsReducer;
