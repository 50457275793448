import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ComplaintsSaga
 */

/**
 *
 * @param {complaints} payload complains data
 */
export function setComplaints(complaints) {
  store.dispatch({
    type: actionTypes.SET_COMPLAINTS_DATA,
    payload: {
      complaints: complaints,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function createComplaint(data) {
  store.dispatch({
    type: actionTypes.CREATE_COMPLAINTS,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {data} payload complains data
 */
export function fixComplaints(
  complaintId,
  locationId,
  employeeId,
  proof,
  navigate
) {
  store.dispatch({
    type: actionTypes.FIX_COMPLAINTS,
    payload: {
      complaintId: complaintId,
      employeeId: employeeId,
      locationId: locationId,
      proof: proof,
      navigate: navigate,
    },
  });
}

export function updateLinkComplaint(complaintId, data) {
  store.dispatch({
    type: actionTypes.UPDATE_LINK_COMPLAINT,
    payload: {
      complaintId: complaintId,
      data: data,
    },
  });
}
