import createSagaMiddleware from "@redux-saga/core";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import LogRocket from "logrocket";
import rootSaga from "./rootSaga";
import { persistedReducer } from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();
//compose with redux devTool
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//store configuration
const store = createStore(
  persistedReducer,
  composeEnhancer(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(LogRocket.reduxMiddleware())
  )
);
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
