import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import profileReducer from "./profile/reducer";
import authReducer from "./authentication/reducer";
import complaintsReducer from "./complaints/reducer";
import locationsReducer from "./locations/reducer";
import callsReducer from "./calls/reducer";

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const callsPersistConfig = {
  key: "calls",
  storage: storage,
  blacklist: ["loading"],
};

const complaintsPersistConfig = {
  key: "complaints",
  storage: storage,
  blacklist: ["loading"],
};

const locationsPersistConfig = {
  key: "locations",
  storage: storage,
  blacklist: ["loading"],
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "profile", "status"],
};

//root reducer
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  calls: persistReducer(callsPersistConfig, callsReducer),
  complaints: persistReducer(complaintsPersistConfig, complaintsReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  locations: persistReducer(locationsPersistConfig, locationsReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
