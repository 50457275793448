import React, { useState, useRef, useEffect } from "react";
import "./Select.css";
import {
  AddIcon,
  ArrowIcon,
  BackArrowIcon,
  CloseIcon,
  ErrorIcon,
} from "../../Assets/assets";
import { isValidArray, isValidString } from "../../Services/validators";
import InputBox from "../InputBox/InputBox";
import Button from "../Button/Button";

function Select(props) {
  const [chips, setChips] = useState([]);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const selectedChipIndexRef = useRef(null);
  const label = useRef(null);
  const [showBottomModal, setShowBottomModal] = useState(false);
  // set chips
  useEffect(() => {
    if (props.chips) {
      setChips([...props.chips]);
    }
  }, [props.chips]);

  const onChipClick = (event, index) => {
    event.stopPropagation();

    if (selectedChipIndex === index) {
      let newArray = [...chips];
      newArray.splice(index, 1);
      typeof props.onChipAdded === "function" &&
        props.onChipAdded([...newArray]);
      setSelectedChipIndex(null);
      selectedChipIndexRef.current = null;
    } else {
      selectedChipIndexRef.current = index;
      setSelectedChipIndex(index);
    }
  };

  return (
    <>
      <div
        className={`position-relative min-height-48px inherit-parent-width inherit-parent-height select-border-bottom-default padding-top-medium  ${
          props.disabled === true ? "background-color-grey" : ""
        }`}
        data-cy={
          props["data-cy"] ? props["data-cy"] : `${props.name}-select-box-comp`
        }
        onClick={() => {
          label.current && label.current.classList.add("active");
          setShowBottomModal(true);
        }}
      >
        <label
          data-cy={`label-${props.name}`}
          ref={label}
          className={`font-color-secondary select-label position-absolute font-family-RHD-medium font-size-medium ${
            props.labelClassName ? props.labelClassName : ""
          } ${
            isValidArray(chips) || props.alwaysActive === true ? "active" : ""
          } ${props.disabled ? " disabled " : ""}
          `}
        >
          {props.label}
        </label>
        <section className="inherit-parent-width inherit-parent-height">
          {isValidArray(chips) && (
            <section
              className="max-height-128px flex-direction-row gap-0-point-5em margin-top-small flex-wrap padding-top-default padding-bottom-default padding-right-medium"
              data-cy="chips-container"
            >
              {isValidArray(chips) &&
                chips.map((chipData, index) => (
                  <div
                    data-cy={`${chipData}-chip${
                      selectedChipIndex === index ? "-selected" : ""
                    }`}
                    className={`text-transform-capitalize text-overflow-ellipsis padding-small ${
                      props.removeChip ? "" : "border-2px-lite-grey"
                    } text-transform-uppercase border-radius-default font-size-small font-family-RHD-regular font-color-secondary ${
                      selectedChipIndex === index
                        ? "background-color-lite-grey"
                        : ""
                    } flex-center-children-vertically `}
                    key={`chip-data-${index}`}
                    onClick={
                      props.removeChip
                        ? () => {}
                        : (event) => {
                            onChipClick(event, index);
                          }
                    }
                  >
                    <span>{chipData}</span>
                    {selectedChipIndex === index && <CloseIcon />}
                  </div>
                ))}
            </section>
          )}
        </section>
        <div className="select-suffix-icon" data-cy="select-toggle-icon">
          <ArrowIcon />
        </div>
      </div>
      <AnimatedBottomModal
        showAddOption={props.showAddOption}
        show={showBottomModal}
        onClickClose={() => {
          props.clearChips();
          setSelectedChipIndex(null);
          setShowBottomModal(false);
        }}
        onApplyClick={() => {
          setSelectedChipIndex(null);
          setShowBottomModal(false);
        }}
        applyButtonDisable={isValidArray(chips) ? false : true}
        languageData={props.languageData}
        chips={chips}
        modalTitle={props.modalTitle}
        selectedChipIndex={selectedChipIndex}
        setSelectedChipIndex={setSelectedChipIndex}
        selectedChipIndexRef={selectedChipIndexRef}
        label={label}
        onChipAdded={props.onChipAdded}
        name={props.name}
        options={props.options}
        multiSelect={props.multiSelect}
        onChipClick={onChipClick}
        loading={props.loading}
        maxLength={props.maxLength}
      />
    </>
  );
}

export default Select;

function AnimatedBottomModal(props) {
  const [entranceAnimation, setEntranceAnimation] = useState(false);
  const childElementRef = useRef(null);
  const bottomCardRef = useRef(null);
  const [value, setValue] = useState("");
  const inputRef = useRef(null);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [complete, setComplete] = useState(false);
  const previousLoadingState = useRef(false);

  const closeModal = () => {
    setEntranceAnimation(false);
    setTimeout(() => {
      bottomCardRef.current &&
        bottomCardRef.current.classList.remove("inherit-parent-height");
      bottomCardRef.current &&
        bottomCardRef.current.classList.add("inherit-parent-height");
      props.onClickClose();
    }, 5000);
  };
  // set searched options
  useEffect(() => {
    setSearchedOptions([...props.options]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set complete status
  useEffect(() => {
    if (previousLoadingState.current === true && props.loading === false) {
      setComplete(true);
    } else {
      if (complete) setComplete(false);
    }
    previousLoadingState.current = props.loading;
    // eslint-disable-next-line
  }, [props.loading]);

  //set animation
  useEffect(() => {
    if (props.show === true) {
      setEntranceAnimation(true);
      inputRef.current && inputRef.current.focus();
    }
    if (props.closeModal) {
      props.closeModal(closeModal);
    }
    if (!props.show && !isValidArray(props.chips)) {
      props.label.current && props.label.current.classList.remove("active");
    }
    // eslint-disable-next-line
  }, [props.show]);

  const onItemSelect = (item) => {
    let newList = [];
    if (props.multiSelect === true) {
      inputRef.current && inputRef.current.focus();
      const prevState = [...props.chips];
      if (prevState.includes(item)) {
        newList = prevState.filter((e) => e !== item);
      } else {
        newList = [item, ...prevState];
      }
      if (newList.length > 0) {
        props.label.current.classList.add("active");
      } else {
        props.label.current.classList.remove("active");
      }
    } else {
      const prevState = [...props.chips];
      if (prevState.includes(item)) {
        newList = [];
      } else {
        newList = [item];
      }
    }
    props.onChipAdded && props.onChipAdded([...newList]);
  };

  const shouldShowAddOptionButton = () => {
    if (props.name === "pctNo") {
      return false;
    }
    let result = true;
    if (!isValidArray(searchedOptions)) {
      if (isValidArray(props.chips)) {
        props.chips.some((chip) => {
          const _returnResult = chip.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      } else {
        result = true;
      }
    } else if (isValidString(value) && isValidArray(searchedOptions)) {
      if (isValidArray(props.chips)) {
        props.chips?.some((chip) => {
          const _returnResult = chip.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      } else {
        searchedOptions.some((option) => {
          const _returnResult = option.toLowerCase() === value.toLowerCase();
          if (_returnResult) {
            result = false;
          }
          return _returnResult;
        });
      }
      // return result;
    } else {
      result = false;
    }

    return result;
  };

  const onSearch = (text) => {
    if (text && text.length === 0) {
      setSearchedOptions([...props.options]);
      return;
    }
    if (props.name === "pctNo") {
      if (/\d/.test(text)) {
        const searchedList = props.options?.filter((item) => {
          return item.PCTNo.includes(text);
        });
        setSearchedOptions([...searchedList]);
      } else {
        text = text.toLowerCase().split(" ");
        const searchedList = props.options?.filter((item) => {
          return item.location?.toLowerCase().includes(text);
        });
        setSearchedOptions([...searchedList]);
      }
    } else {
      text = text.toLowerCase().split(" ");
      const searchedList = props.options?.filter((item) => {
        item = item.toLowerCase();
        return text.every((el) => {
          return item.indexOf(el) > -1;
        });
      });
      setSearchedOptions([...searchedList]);
    }
  };

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="position-absolute right-0 top-30-percentage circular-loader"
          data-cy={`${props.name}-select-box-loader-icon`}
        />
      );
    } else if (isValidArray(props.chips) && complete === true) {
      return (
        <div
          className={`position-absolute right-0 top-30-percentage height-width-1em ${props.iconClassNames}`}
          data-cy={`${props.name}-select-box-complete-icon`}
        >
          <ErrorIcon />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {props.show && (
        <article
          style={{ top: 0, left: 0 }}
          className="inherit-parent-height inherit-parent-width position-absolute z-index-1"
          data-cy="issue-modal"
        >
          <main
            className={`inherit-parent-height inherit-parent-width display-flex justify-content-flex-end align-items-flex-end  animated-bottom-modal-anime ${
              entranceAnimation ? "animated-bottom-modal-anime-entrance" : ""
            }`}
            ref={childElementRef}
          >
            <div
              ref={bottomCardRef}
              className="padding-larger inherit-parent-width flex-center-children-vertically flex-direction-column inherit-parent-height background-white"
            >
              <div className="inherit-parent-height inherit-parent-width">
                <div className="inherit-parent-width margin-bottom-large  flex-align-items-center display-flex flex-justify-content-space-between">
                  <div onClick={props.onClickClose}>
                    <BackArrowIcon />
                  </div>
                  <div
                    className="text-transform-uppercase font-weight-normal font-family-RHD-regular font-color-secondary"
                    data-cy="modal-title"
                  >
                    {props.modalTitle}
                  </div>
                  <div className="padding-default"></div>
                </div>

                {isValidArray(props.chips) && (
                  <section
                    className="inherit-parent-width flex-direction-row gap-0-point-5em flex-wrap padding-top-default padding-bottom-default"
                    data-cy="modal-chips-container"
                  >
                    {props.chips.map((chipData, index) => (
                      <div
                        data-cy={`modal-${chipData}-chip${
                          props.selectedChipIndex === index ? "-selected" : ""
                        }`}
                        className={`text-transform-capitalize text-overflow-ellipsis padding-small border-2px-lite-grey text-transform-uppercase border-radius-default font-size-small font-family-RHD-regular font-color-secondary ${
                          props.selectedChipIndex === index
                            ? "background-color-lite-grey"
                            : ""
                        } flex-center-children-vertically `}
                        key={`chip-data-${index}`}
                        onClick={(event) => props.onChipClick(event, index)}
                      >
                        <span>{chipData}</span>
                        {props.selectedChipIndex === index && <CloseIcon />}
                      </div>
                    ))}
                  </section>
                )}
                <section className="inherit-parent-width position-relative">
                  <InputBox
                    className={`font-size-medium padding-left-default padding-right-default  display-block inherit-parent-width font-family-RHD-medium input-search-border-bottom-default padding-left-small border-radius-0`}
                    data-cy={`${props.name}-select-box-search-input`}
                    type="text"
                    name="search"
                    onFocus={() => {
                      bottomCardRef.current &&
                        bottomCardRef.current.classList.add(
                          "inherit-parent-height"
                        );
                    }}
                    value={value}
                    label="SEARCH"
                    removeResponsive={true}
                    autoComplete="off"
                    onChange={(event) => {
                      event.preventDefault();
                      setValue(event.target.value);
                      onSearch(event.target.value);
                    }}
                    inputMode="search"
                    maxLength={props.maxLength ? props.maxLength : 25}
                  />
                  <InputSuffixIcon />
                </section>

                <section
                  style={
                    isValidArray(props.chips)
                      ? { height: "calc(100% - 191px)" }
                      : { height: "calc(100% - 150px)" }
                  }
                  data-cy={`${props.name}-options-section`}
                  className={`inherit-parent-width background-color-white overflow-auto 
                ${
                  isValidArray(props.chips)
                    ? "flex-basis-70-percentage"
                    : "flex-basis-80-percentage"
                } 
                `}
                >
                  <ul className="list-style-type-none ">
                    <li className="hide-scroll-bar">
                      {searchedOptions?.map((data, index) => {
                        return (
                          <div key={index}>
                            <ListItem
                              data={data}
                              category={props.name}
                              index={index}
                              onItemSelect={onItemSelect}
                              selected={
                                props.name === "pctNo"
                                  ? props.chips[0] ===
                                    `${data.PCTNo?.toString().padStart(
                                      2,
                                      "0"
                                    )} - ${data.location}`
                                  : props.chips.includes(data)
                              }
                            />
                          </div>
                        );
                      })}
                    </li>
                    <li>
                      {props.showAddOption !== false &&
                        shouldShowAddOptionButton() === true && (
                          <div
                            className="inherit-parent-width padding-left-small padding-right-small padding-top-large flex-direction-row flex-justify-content-space-between"
                            onClick={() => {
                              let newArray = [value, ...props.chips];
                              props.onChipAdded &&
                                props.onChipAdded([...newArray]);
                              setValue("");
                              setSearchedOptions([...props.options]);
                              inputRef.current && inputRef.current.focus();
                            }}
                            data-cy="add-custom-value-button"
                          >
                            <span className="font-family-RHD-medium font-size-medium">
                              Add "{`${value}`}"
                            </span>
                            <div>
                              <AddIcon />
                            </div>
                          </div>
                        )}
                    </li>
                  </ul>
                </section>
                <div
                  style={{
                    position: "sticky",
                    bottom: "16px",
                  }}
                  className="inherit-parent-width padding-top-large"
                >
                  <Button
                    text="Apply"
                    boxShadow={false}
                    onClick={props.onApplyClick}
                    disabled={props.applyButtonDisable}
                    data-cy={"Apply-button"}
                    type="button"
                  />
                </div>
              </div>
            </div>
          </main>
        </article>
      )}
    </>
  );
}

function ListItem(props) {
  const [selected, setSelected] = useState(false);
  // set selected data
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  const CheckIcon = () => {
    if (selected) {
      return (
        <svg
          data-cy={`${props.data}-selected`}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="#2965DD"
          className="bi bi-check2"
          viewBox="0 0 16 16"
        >
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
      );
    } else {
      return null;
    }
  };
  return (
    <div
      className={`padding-left-small padding-right-small ${
        props.index !== 0 ? "padding-top-large" : ""
      }`}
      onClick={() => {
        setSelected((prevState) => !prevState);
        props.onItemSelect(
          props.category === "pctNo"
            ? `${props.data.PCTNo?.toString().padStart(2, "0")} - ${
                props.data.location
              }`
            : props.data
        );
      }}
      data-cy={`${
        props.category === "pctNo" ? props.data.documentId : props.data
      }-select-option-item`}
    >
      <div className="flex-center-children-vertically flex-justify-content-space-between">
        <span className="font-family-RHD-regular font-size-medium font-color-secondary ">
          {props.category === "pctNo" ? (
            <div>{`${props.data.PCTNo?.toString().padStart(2, "0")} - ${
              props.data.location
            }`}</div>
          ) : (
            <div>{props.data}</div>
          )}
        </span>
        <CheckIcon />
      </div>
    </div>
  );
}
