import store from "../store";
import { statusActionTypes } from "./saga";

/**
 * @module StatusSaga
 */

/**
 *
 * @param {object} payload payload has code ("custom") and message ("your message")
 */
export function setErrorStatus(payload) {
  store.dispatch({
    type: statusActionTypes.SET_ERROR_STATUS,
    payload: payload,
  });
}

/**
 *
 * @param {string} message add message "your message"
 */
export function setSuccessStatus(message) {
  store.dispatch({
    type: statusActionTypes.SET_SUCCESS_STATUS,
    payload: {
      message: message,
      code: "success",
    },
  });
}

/**
 * Removes the message which is already present
 */

export function removeStatus() {
  store.dispatch({
    type: statusActionTypes.CLEAR_STATUS,
  });
}
