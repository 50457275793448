import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module CallsSaga
 */

/**
 *
 * @param {object} payload data has locationData
 */
export function setCallsData(data) {
  store.dispatch({
    type: actionTypes.ADD_CALLS_DATA,
    payload: {
      data: data,
    },
  });
}
