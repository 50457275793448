import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  ADD_CALLS_DATA: "ADD_CALLS_DATA",
};

function* addCallsDataWorker(action) {
  try {
    setCallsLoading(true);
    yield put({
      type: "SET_CALLS_DATA",
      payload: { data: action.payload.data },
    });
    setCallsLoading(false);
  } catch (error) {
    setCallsLoading(false);
    setErrorStatus(error);
  }
}

export default function* callsWatcher() {
  yield all([takeEvery("ADD_CALLS_DATA", addCallsDataWorker)]);
}

function* setCallsLoading(bool) {
  yield put({
    type: "SET_CALLS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
