import React from "react";
import "./Button.css";
import "../../index.css";
import { rippleEffect } from "../../Utils/constants";

function Button(props) {
  const getClassName = () => {
    let className = "";
    if (props.disabled === true) {
      className +=
        "inherit-parent-width disabled-button-background padding-top-medium padding-bottom-medium font-color-secondary font-family-RHD-medium border-radius-default font-size-medium cursor-not-allowed font-color-white max-height-44px";
      className += ` ${
        props.boxShadow === false || props.disabled === true
          ? "box-shadow-none"
          : "box-shadow-default"
      }`;
      className += ` ${props.className}`;
      return className;
    }

    className += `inherit-parent-width padding-top-medium padding-bottom-medium font-family-RHD-medium border-radius-default font-size-medium cursor-pointer `;
    // variant
    switch (props.variant) {
      // borderedSecondary => secondary
      case "secondary": {
        className +=
          "font-color-secondary background-white border-1px-e5e5e5 max-height-44px";
        break;
      }
      // filledRed => danger
      case "danger": {
        className += "font-color-white background-color-red max-height-44px";
        break;
      }

      case "transparent": {
        className +=
          " font-color-white button-background-transparent font-family-RHD-medium max-height-44px";
        break;
      }

      case "primaryBorder": {
        className +=
          " font-color-primary background-white bordered-button-background font-family-RHD-medium max-height-44px";
        break;
      }
      // filled => primary
      default: {
        className +=
          " background-color-primary font-color-white max-height-44px";
        break;
      }
    }
    className += ` ${
      props.boxShadow === false ? "box-shadow-none" : "box-shadow-default"
    }`;
    className += ` ${props.className}`;
    return className;
  };

  const DefaultButton = () => {
    return (
      <button
        data-cy={props["data-cy"] ? props["data-cy"] : "button-component"}
        type={props.type}
        className={getClassName()}
        onClick={(event) => {
          rippleEffect(event);
          if (props.loading === true) return;
          if (typeof props.onClick === "function") {
            props.onClick(event);
          }
        }}
        disabled={props.disabled || props.loading}
      >
        {props.loading !== true ? (
          props.text
        ) : (
          <div className="flex-place-children-page-center padding-vertical-small margin-top-smaller">
            <div
              className={`${
                props.disabled
                  ? "dot-pulse-dark"
                  : props.variant === "primaryBorder"
                  ? "dot-pulse-primary"
                  : "dot-pulse"
              }`}
            />
          </div>
        )}
      </button>
    );
  };
  return (
    <React.Fragment key={props.buttonKey}>
      <DefaultButton />
    </React.Fragment>
  );
}

export default Button;
