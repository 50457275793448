import { getVerificationCodeFromEmulator } from "./api.mock";

//DEFAULT TESTING DEVICES
export const devices = [
  { name: "samsung-s10", orientation: "portrait" },
  { name: "macbook-11", orientation: "portrait" },
  { name: "macbook-11", orientation: "landscape" }
];

export async function getVerificationCode() {
  if (process.env.REACT_APP_STAGING === "local") {
    const otpResponse = await getVerificationCodeFromEmulator();
    const verificationCode = otpResponse.verificationCodes[otpResponse.verificationCodes.length - 1];
    return verificationCode.code;
  } else {
    const code = "000000";
    return code;
  }
}