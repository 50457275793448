import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ProfileSaga
 */


/**
 *
 * @param {profiles} payload profile data
 */
export function setAccountProfiles(profiles) {
  store.dispatch({
    type: actionTypes.SET_ACCOUNT_PROFILES,
    payload: {
      profiles: profiles,
    },
  });
}
