import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { updateCompliant, updateLinkCompliant } from "../../Services/database";
import { uploadCompliantProof } from "../../Services/storage";
import { isValidArray } from "../../Services/validators";
import { bucketNames, removeLeadingZeros } from "../../Utils/constants";
import store from "../store";
import { raiseComplaint } from "../../Services/api";

export const actionTypes = {
  CREATE_COMPLAINTS: "CREATE_COMPLAINTS",
  SET_COMPLAINTS_DATA: "SET_COMPLAINTS_DATA",
  FIX_COMPLAINTS: "FIX_COMPLAINTS",
  UPDATE_LINK_COMPLAINT: "UPDATE_LINK_COMPLAINT",
};

function* setComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    yield put({
      type: "SET_COMPLAINTS",
      payload: action.payload.complaints,
    });

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* createComplaintWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const formData = action.payload.data;
    const profileData = store.getState().profile.data;
    const accessToken = store.getState().auth.data.accessToken;

    const locationData =
      store.getState().locations?.data &&
      Object.values(store.getState().locations?.data)?.find(
        (el) => String(el.PCTNo) === removeLeadingZeros(formData.pctNo)
      );

    if (formData.pctNo !== "" && locationData === undefined) {
      let error = new Error("Invalid PCT Number");
      error.code = "custom";
      throw error;
    }

    if (formData?.issue?.length > 0 && accessToken) {
      for (let index = 0; index < formData.issue.length; index++) {
        let data = new FormData();
        if (isValidArray(formData.mediaData)) {
          for (let i = 0; i < formData.mediaData.length; i++) {
            if (formData.mediaData[i]) {
              data.append("files", formData.mediaData[i]);
            }
          }
        }
        data.append("category", formData.issue?.[index]);
        data.append("createdAt", JSON.stringify(+new Date()));
        data.append(
          "issuedBy",
          JSON.stringify({
            issuedAt: +new Date(),
            type: "customerSupport",
            userDetails: {
              name: profileData?.name,
              employeeId: profileData?.employeeId,
              phoneNumber:
                profileData?.phoneNumber === "+91"
                  ? ""
                  : profileData?.phoneNumber,
            },
            linkedWith: [
              {
                source: {
                  type: formData.sourceType,
                  ...(formData.sourceType === "ExoTel Call"
                    ? { callId: formData.callId }
                    : {}),
                },
                issuedAt: +new Date(),
                userDetails: {
                  name: formData.name,
                  phoneNumber: formData.phoneNumber,
                },
              },
            ],
          })
        );

        if (formData.pctNo !== "") {
          data.append("locationId", locationData.documentId);
        }

        data.append(
          "status",
          JSON.stringify({
            currentStatus: "OPEN",
            updatedAt: +new Date(),
          })
        );

        yield raiseComplaint(data, accessToken);
      }

      // yield Promise.all(complaintBatch);
      setSuccessStatus("Complaint Created Successfully");
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fixComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    let imageFileUrls = [];
    let audioFileUrl = [];

    if (isValidArray(action.payload?.proof?.image)) {
      for (let index = 0; index < action.payload.proof?.image.length; index++) {
        yield uploadCompliantProof(
          action.payload.proof?.image[index],
          action.payload.complaintId,
          action.payload.locationId
        );

        imageFileUrls.push(
          `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.image?.[index]?.name}`
        );
      }
    }

    if (action.payload?.proof?.audio) {
      yield uploadCompliantProof(
        action.payload.proof.audio,
        action.payload.complaintId,
        action.payload.locationId
      );
      audioFileUrl.push(
        `${bucketNames.defaultBucket}/${action.payload.locationId}/complaints/${action.payload.complaintId}/closure/${action.payload.proof.audio?.name}`
      );
    }

    yield updateCompliant(
      action.payload.complaintId,
      action.payload.employeeId,
      isValidArray(action.payload.proof?.image) && action.payload.proof?.audio
        ? [...imageFileUrls, ...audioFileUrl]
        : isValidArray(action.payload?.proof?.image)
        ? imageFileUrls
        : action.payload?.proof?.audio
        ? audioFileUrl
        : ""
    );

    yield setSuccessStatus("Submitted successfully");
    if (action.payload.navigate) {
      action.payload.navigate("/complaints");
    }

    yield setComplaintsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* updateLinkComplaintWorker(action) {
  try {
    yield setComplaintsLoading(true);

    yield updateLinkCompliant(action.payload.complaintId, action.payload.data);
    yield setSuccessStatus("Linked successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("linkComplaintWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

export default function* complaintWatcher() {
  yield all([
    takeEvery("SET_COMPLAINTS_DATA", setComplaintsWorker),
    takeEvery("CREATE_COMPLAINTS", createComplaintWorker),
    takeEvery("FIX_COMPLAINTS", fixComplaintsWorker),
    takeEvery("UPDATE_LINK_COMPLAINT", updateLinkComplaintWorker),
  ]);
}

function* setComplaintsLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
