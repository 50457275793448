import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  ADD_LOCATIONS_DATA: "ADD_LOCATIONS_DATA",
};

function* addLocationsDataWorker(action) {
  try {
    setLocationsLoading(true);
    yield put({
      type: "SET_LOCATIONS_DATA",
      payload: { data: action.payload.data },
    });
    setLocationsLoading(false);
  } catch (error) {
    setLocationsLoading(false);
    setErrorStatus(error);
  }
}

export default function* locationsWatcher() {
  yield all([takeEvery("ADD_LOCATIONS_DATA", addLocationsDataWorker)]);
}

function* setLocationsLoading(bool) {
  yield put({
    type: "SET_LOCATIONS_LOADING",
    payload: {
      loading: bool,
    },
  });
}
