import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  SET_ACCOUNT_PROFILES: "SET_ACCOUNT_PROFILES",
};

function* setProfilesWorker(action) {
  try {
    yield put({
      type: "SET_PROFILES",
      payload: {
        ...action.payload.profiles,
        linkedProcedures: action.payload.profiles?.linkedProcedures?.filter(
          (data) => data.processing !== true
        ),
      },
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* profileWatcher() {
  yield all([takeEvery("SET_ACCOUNT_PROFILES", setProfilesWorker)]);
}

// function* setProfileLoading(bool) {
//   yield put({
//     type: "SET_PROFILE_LOADING",
//     payload: {
//       loading: bool,
//     },
//   });
// }
